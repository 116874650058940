import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "rgba(0,0,0,1)"};
  color: ${(p) => (p.color ? "rgba(0,0,0,1)" : "#fff")};
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #edf3f5;
  border-radius: 0spx;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 10px rgba(0,0,0, 0.4);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, 1);
    animation: 
  }
`;
