import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import Logo from "../../assets/images/svg/logo.svg";
import Github from "../../assets/images/svg/github.svg";
import LinkedIn from "../../assets/images/svg/linkedin.svg";
import Medium from "../../assets/images/svg/medium.svg";
import { links } from "../../globalConstants";

//import i18n from "i18next";
import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  Chat,
  FooterContainer,
  Language,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const SocialLink = ({ href, src }: SocialLinkProps) => {
  return (
    <div>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    </div>
  );
};

const Footer = ({ t }: any) => {
  /*const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };*/

  return (
    <>
      <FooterSection>
        <Container>
          <Row>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              {/*<Large to="/">{t("Get our attention")}</Large>*/}
              <a href={`mailto:${links.email}`}>
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              {/*<Title>{t("Company")}</Title>
              <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
  </Large>*/}
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              {/*<Title>{t("Policy")}</Title>
              <Large to="/" left="true">
                {t("Application Security")}
</Large>
              <Large left="true" to="/">
                {t("Software Design Principles")}
              </Large>*/}
            </Col>
          </Row>
          <Row justify="space-between">
            {/*<Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
  </Col>*/}
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src={Logo}
                  aria-label="homepage"
                  width="110px"
                  height="110px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink href={links.github} src={Github} />
              <SocialLink href={links.linkedin[0]} src={LinkedIn} />
              <SocialLink href={links.medium} src={Medium} />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
